import { useEffect } from "react";
import EventsAnalytics from "../common/services/events-analytics";

export default function useEventsUserGuideController() {
  useEffect(() => {
    EventsAnalytics.trackEventsUserGuide();
  }, []);

  const imageMap = (images: string[]) => {
    const regex = /^\/static\/media\/([\w-]*).[a-z0-9]*.png$/;
    // @ts-ignore
    return Object.fromEntries(images.map((img) => ([`${regex.exec(img)[1]}.png`, img])));
  };

  return ({
    getImageMap: imageMap,
  });
}
