import React, { FunctionComponent } from "react";
import { TeamTypeReadableLabel } from "../../create-team/redux";
import "./create-team-action-unauthorized.scss";
import NeoLink from "../../../common/components/neo-link";

const CreateTeamActionUnauthorized: FunctionComponent = () => (
  <div className="CreateTeamUnauthorized">
    <div className="message">
      <div>{`Sorry, you do not have permission to create ${TeamTypeReadableLabel.GITS} teams.`}</div>
      <div>
        Please send your request to&nbsp;
        <NeoLink
          link="mailto:engineering-platform-support@thoughtworks.com"
          className="contact-email"
          external
        >
          engineering-platform-support@thoughtworks.com
        </NeoLink>
        &nbsp;to create a new&nbsp;
        {TeamTypeReadableLabel.GITS}
        {" "}
        team.
      </div>
    </div>
  </div>
);

export default CreateTeamActionUnauthorized;
