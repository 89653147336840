import "./index.scss";
import React from "react";
import useOthersTeamUnAuthorizedMessageController from "./controller";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import NeoLink from "../neo-link";

const OthersTeamUnAuthorizedMessage = createFunctionalComponentFromView(
  useOthersTeamUnAuthorizedMessageController,
  (controller) => (
    <div className="OthersTeamUnAuthorizedMessage">
      <div>
        {controller.errorMessage}
        <br />
        To create a team of this type, please send an email to&nbsp;
        <NeoLink
          link={`mailto:${controller.neoSupportEmail}`}
          className="contact-email"
          external
        >
          {controller.neoSupportEmail}
        </NeoLink>
      </div>
    </div>
  ),
);

export default OthersTeamUnAuthorizedMessage;
