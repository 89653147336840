import React from "react";
import "./index.scss";
import useTeamTypesController from "./controller";
import CreateTeamActionUnauthorized from "./create-team-action-unauthorized";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import TeamTypesButtons from "./team-types-button";
import NeoModalDialog from "../../../common/components/neo-modal-dialog";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { TeamType } from "../../create-team/redux";
import OthersTeamUnAuthorizedMessage from "../../../common/components/others-team-unauthorized-message";

const TeamTypesSelection = createFunctionalComponentFromView<ReturnType<typeof useTeamTypesController>, unknown>(
  useTeamTypesController,
  (controller) => {
    const renderCreateOthersAndGITSTeamUnAuthorized = () => {
      if (controller.createTeamUnAuthorizedPageIsActive) {
        return controller.createTeamUnAuthorizedPageTeamType === TeamType.OTHERS
          ? (
            <OthersTeamUnAuthorizedMessage
              errorMessage="Sorry, you do not have permission to create Others team !!"
            />
          )
          : <CreateTeamActionUnauthorized />;
      }
      return null;
    };

    const renderTeamTypesButton = () => {
      if (!controller.createTeamUnAuthorizedPageIsActive) {
        return (
          <>
            <TeamTypesButtons />
          </>
        );
      }
    };

    const renderTeamTypes = () => {
      if (controller.shouldRenderTeamTypes()) {
        return (
          <div className="TeamTypes">
            {renderTeamTypesButton()}
            {renderCreateOthersAndGITSTeamUnAuthorized()}
          </div>
        );
      }
    };

    const renderError = () => {
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMessage()!)}
          />
        );
      }
    };

    const renderModalBody = () => (
      <>
        {renderTeamTypes()}
        <Progress text={controller.getProgressMessage()} />
        {renderError()}
      </>
    );

    return (
      <NeoModalDialog
        dialogTitle="Choose Team Type"
        show={controller.showChooseTeamTypeForTeamCreationModal}
        onHide={controller.onClickHideChooseTeamTypeForTeamCreationModal}
        modalSize="xl"
        dontAutoHideOnButtonClick={true}
        dialogClassName="team-type-dialog"
        primaryButton={{
          show: true,
          onClick: controller.getPrimaryButtonOnClickHandler,
          label: controller.getModalPrimaryButtonLabel(),
        }}
      >
        {renderModalBody()}
      </NeoModalDialog>
    );
  });

export default TeamTypesSelection;
